import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import GWlp_1click from "src/components/gwlp_1click"
import Btf_cb_ez from "src/components/btf_cb_ez"
import CtaDisclosureCb2 from "src/components/ctadisclosurecb2"

const GWlpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Clear | Efficient Web Browsing</title>
    <style type="text/css">{`
       #ctadisclosurecb2-module--ctadisclaimer {
        margin:0 auto !important;
        font-size:13px;
        width:500px;
       }

       #ctadisclosurecb2-module--ctadisclaimer p {
        font-size:12px;
        line-height:125%;
       }

    `}
    </style>
    </Helmet>
    <section>
      <GWlp_1click data={GWlpData}></GWlp_1click>
      <CtaDisclosureCb2></CtaDisclosureCb2>
      <Btf_cb_ez data={btfData}>
        <CtaDisclosureCb2></CtaDisclosureCb2>
      </Btf_cb_ez>
      </section>
    </HomepageLayout>


  )
  }
